import React, { useEffect, useRef, useState } from 'react';

// Importing images from the assets folder
import image1 from '../assets/images/slider-1.png';
import image2 from '../assets/images/slider-2.png';
import image3 from '../assets/images/slider-3.png';
import image4 from '../assets/images/slider-4.png';
import image5 from '../assets/images/slider-5.png';
import image6 from '../assets/images/slider-6.png';
import image7 from '../assets/images/slider-7.png';
import image8 from '../assets/images/slider-8.png';
import image9 from '../assets/images/slider-9.png';
import image10 from '../assets/images/slider-10.png';
import image11 from '../assets/images/slider-11.png';
import image12 from '../assets/images/slider-12.png';
import image13 from '../assets/images/slider-13.png';
import image14 from '../assets/images/slider-14.png';
import image15 from '../assets/images/slider-15.png';
import image16 from '../assets/images/slider-16.png';
import image17 from '../assets/images/slider-17.png';
import image18 from '../assets/images/slider-18.png';
import image19 from '../assets/images/slider-19.png';
import image20 from '../assets/images/slider-20.png';
import { getImageSrc } from '../reuse';

const VerticalSlider= ({branding}) => {
  const images = [
    getImageSrc(branding?.BRAND0104, image1),
    getImageSrc(branding?.BRAND0105, image2),
    getImageSrc(branding?.BRAND0106, image3),
    getImageSrc(branding?.BRAND0107, image4),
    getImageSrc(branding?.BRAND0108, image5),
    getImageSrc(branding?.BRAND0109, image6),
    getImageSrc(branding?.BRAND0110, image7),
    getImageSrc(branding?.BRAND0111, image8),
    getImageSrc(branding?.BRAND0112, image9),
    getImageSrc(branding?.BRAND0113, image10),
    getImageSrc(branding?.BRAND0114, image11),
    getImageSrc(branding?.BRAND0115, image12),
    getImageSrc(branding?.BRAND0116, image13),
    getImageSrc(branding?.BRAND0117, image14),
    getImageSrc(branding?.BRAND0118, image15),
    getImageSrc(branding?.BRAND0119, image16),
    getImageSrc(branding?.BRAND0120, image17),
    getImageSrc(branding?.BRAND0121, image18),
    getImageSrc(branding?.BRAND0122, image19),
    getImageSrc(branding?.BRAND0123, image20)
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const imagesPerRow = 5;
  const totalRows = Math.ceil(images.length / imagesPerRow);
  const displayRows = 4;


  

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (nextIndex >= totalRows) {
          return totalRows;
        }
        return nextIndex;
      });
    }, 4000);

    return () => clearInterval(interval);
  }, [totalRows]);

  useEffect(() => {
    if (carouselRef.current) {
      if (currentIndex === totalRows) {
        requestAnimationFrame(() => {
          carouselRef.current.style.transition = 'none';
          carouselRef.current.style.transform = `translateY(0px)`;
          requestAnimationFrame(() => {
            setCurrentIndex(1);
            carouselRef.current.style.transition = 'transform 1.5s ease';
          });
        });
      } else {
        carouselRef.current.style.transition = 'transform 1.5s ease';
        carouselRef.current.style.transform = `translateY(-${currentIndex * (100 / displayRows)}%)`;
      }
    }
  }, [currentIndex, totalRows, displayRows]);

  return (
    <div className="carousel-container">
      <div className="carousel-list" ref={carouselRef}>
        {[...images, ...images].map((image, index) => (
          <div key={index} className="carousel-item">
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalSlider;