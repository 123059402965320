  export const displayBase64 = (image)=>"data:image/png;base64,"+image

 export const isBase64Image = (image) => {
    if(image?.startsWith('iVBORw0KGgoAAAANSUhEUg') || image?.startsWith('/9j/4AAQSkZJRgA')){
      return true
    }
    return false
  }

export const getImageSrc = (image, local)=> image?.startsWith('iVBORw0KGgoAAAANSUhEUg') || image?.startsWith('/9j/4AAQSkZJRgA') ? "data:image/png;base64,"+image : local

